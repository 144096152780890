import { default as adminsBOvjqm76zAMeta } from "/tmp/build_4d0a0c3b/pages/administration/admins.vue?macro=true";
import { default as _91id_93U7FEgXoogeMeta } from "/tmp/build_4d0a0c3b/pages/administration/billing/[id].vue?macro=true";
import { default as index6gkjsqp9dTMeta } from "/tmp/build_4d0a0c3b/pages/administration/billing/index.vue?macro=true";
import { default as bookingsoyVywj54J7Meta } from "/tmp/build_4d0a0c3b/pages/administration/bookings.vue?macro=true";
import { default as ecostatisticsw28Y8nEBa1Meta } from "/tmp/build_4d0a0c3b/pages/administration/ecostatistics.vue?macro=true";
import { default as exportsgpGZHa78hcMeta } from "/tmp/build_4d0a0c3b/pages/administration/exports.vue?macro=true";
import { default as fidelity9OVP4VzmTcMeta } from "/tmp/build_4d0a0c3b/pages/administration/fidelity.vue?macro=true";
import { default as invoicesbtaQ7isDNrMeta } from "/tmp/build_4d0a0c3b/pages/administration/invoices.vue?macro=true";
import { default as policyRYRVRFFT3zMeta } from "/tmp/build_4d0a0c3b/pages/administration/organization/policy.vue?macro=true";
import { default as _91id_93X5CSPLmVtWMeta } from "/tmp/build_4d0a0c3b/pages/administration/payment/[id].vue?macro=true";
import { default as indexKYdObTjPGWMeta } from "/tmp/build_4d0a0c3b/pages/administration/payment/index.vue?macro=true";
import { default as statisticsmbc7c58VdaMeta } from "/tmp/build_4d0a0c3b/pages/administration/statistics.vue?macro=true";
import { default as indexykRKgsuA0gMeta } from "/tmp/build_4d0a0c3b/pages/administration/teams/[teamId]/index.vue?macro=true";
import { default as invitationjRpT9hXeM1Meta } from "/tmp/build_4d0a0c3b/pages/administration/teams/[teamId]/invitation.vue?macro=true";
import { default as indexXd2mU0mskvMeta } from "/tmp/build_4d0a0c3b/pages/administration/teams/[teamId]/policy/index.vue?macro=true";
import { default as modifyirhd2AkONGMeta } from "/tmp/build_4d0a0c3b/pages/administration/teams/[teamId]/policy/modify.vue?macro=true";
import { default as validatorsj07jb49L4pMeta } from "/tmp/build_4d0a0c3b/pages/administration/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_935GGWxqTHCkMeta } from "/tmp/build_4d0a0c3b/pages/administration/teams/[teamId].vue?macro=true";
import { default as indexp5GOvTBN4JMeta } from "/tmp/build_4d0a0c3b/pages/administration/teams/index.vue?macro=true";
import { default as trip_45labels7nKNC4bNG8Meta } from "/tmp/build_4d0a0c3b/pages/administration/trip-labels.vue?macro=true";
import { default as administrationepUkUEW8R7Meta } from "/tmp/build_4d0a0c3b/pages/administration.vue?macro=true";
import { default as swile9rnbLIS8FsMeta } from "/tmp/build_4d0a0c3b/pages/auth/swile.vue?macro=true";
import { default as indexaJ0GojKF6ZMeta } from "/tmp/build_4d0a0c3b/pages/bookings/index.vue?macro=true";
import { default as indexaBDIwWUoyhMeta } from "/tmp/build_4d0a0c3b/pages/bookings/validator/index.vue?macro=true";
import { default as pendingGw50J9luymMeta } from "/tmp/build_4d0a0c3b/pages/bookings/validator/pending.vue?macro=true";
import { default as _91id_93VVLwDGhxg5Meta } from "/tmp/build_4d0a0c3b/pages/car/[id].vue?macro=true";
import { default as exchangeO9RjdURSSCMeta } from "/tmp/build_4d0a0c3b/pages/flight/[id]/exchange.vue?macro=true";
import { default as farest7bia0tRieMeta } from "/tmp/build_4d0a0c3b/pages/flight/[id]/fares.vue?macro=true";
import { default as indexj8KxS4eOJHMeta } from "/tmp/build_4d0a0c3b/pages/flight/[id]/index.vue?macro=true";
import { default as forgotLEiYIF6qLLMeta } from "/tmp/build_4d0a0c3b/pages/forgot.vue?macro=true";
import { default as get_45swile_45app9Aa9y8lSU1Meta } from "/tmp/build_4d0a0c3b/pages/get-swile-app.vue?macro=true";
import { default as helpBgHjE8nxlGMeta } from "/tmp/build_4d0a0c3b/pages/help.vue?macro=true";
import { default as _91id_93QVboqagl4bMeta } from "/tmp/build_4d0a0c3b/pages/hotel/[id].vue?macro=true";
import { default as _91id_93CEDPRHhlUOMeta } from "/tmp/build_4d0a0c3b/pages/impersonate/[id].vue?macro=true";
import { default as indexsg4hSD0dGSMeta } from "/tmp/build_4d0a0c3b/pages/index.vue?macro=true";
import { default as _91suffix_93Nejxvtm7VUMeta } from "/tmp/build_4d0a0c3b/pages/invitation/[suffix].vue?macro=true";
import { default as _91token_93xyRM3dLtKxMeta } from "/tmp/build_4d0a0c3b/pages/invite/[token].vue?macro=true";
import { default as _91id_93PlpkRc7ucUMeta } from "/tmp/build_4d0a0c3b/pages/journeys/[id].vue?macro=true";
import { default as index5CnuGZTNYYMeta } from "/tmp/build_4d0a0c3b/pages/journeys/index.vue?macro=true";
import { default as members9m91f2vzOgMeta } from "/tmp/build_4d0a0c3b/pages/myteam/members.vue?macro=true";
import { default as policy166RDBTDbqMeta } from "/tmp/build_4d0a0c3b/pages/myteam/policy.vue?macro=true";
import { default as validatorsMzTiKyQbqPMeta } from "/tmp/build_4d0a0c3b/pages/myteam/validators.vue?macro=true";
import { default as myteamHwyiA9GgM2Meta } from "/tmp/build_4d0a0c3b/pages/myteam.vue?macro=true";
import { default as new_45usercFVEWDuou1Meta } from "/tmp/build_4d0a0c3b/pages/new-user.vue?macro=true";
import { default as password0ZZZUpSwg0Meta } from "/tmp/build_4d0a0c3b/pages/password.vue?macro=true";
import { default as indexsw9zjsJbibMeta } from "/tmp/build_4d0a0c3b/pages/search/car/[search_id]/index.vue?macro=true";
import { default as index1SZwN5SHJKMeta } from "/tmp/build_4d0a0c3b/pages/search/car/index.vue?macro=true";
import { default as _91search_id_93ctsX8YRms8Meta } from "/tmp/build_4d0a0c3b/pages/search/car/init/[search_id].vue?macro=true";
import { default as _91search_id_930o0v5hikwmMeta } from "/tmp/build_4d0a0c3b/pages/search/flight/[search_id].vue?macro=true";
import { default as index5siAigPA3kMeta } from "/tmp/build_4d0a0c3b/pages/search/flight/index.vue?macro=true";
import { default as indexxVvoY1vJO6Meta } from "/tmp/build_4d0a0c3b/pages/search/hotel/[search_id]/index.vue?macro=true";
import { default as _91hotelIds_93H7snfTq6BgMeta } from "/tmp/build_4d0a0c3b/pages/search/hotel/[search_id]/rooms/[hotelIds].vue?macro=true";
import { default as indexliI1RYf7mrMeta } from "/tmp/build_4d0a0c3b/pages/search/hotel/index.vue?macro=true";
import { default as _91search_id_93vqZPLhwRHvMeta } from "/tmp/build_4d0a0c3b/pages/search/train/[search_id].vue?macro=true";
import { default as indexfUEhsfPJmfMeta } from "/tmp/build_4d0a0c3b/pages/search/train/index.vue?macro=true";
import { default as searchBxUPXOEsA7Meta } from "/tmp/build_4d0a0c3b/pages/search.vue?macro=true";
import { default as indexJvHGovCFnHMeta } from "/tmp/build_4d0a0c3b/pages/teams/[teamId]/index.vue?macro=true";
import { default as invitationj6od29VyCuMeta } from "/tmp/build_4d0a0c3b/pages/teams/[teamId]/invitation.vue?macro=true";
import { default as policyGnOOAonPLrMeta } from "/tmp/build_4d0a0c3b/pages/teams/[teamId]/policy.vue?macro=true";
import { default as validatorsQYTtCv4PoXMeta } from "/tmp/build_4d0a0c3b/pages/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_938eF2oijT2EMeta } from "/tmp/build_4d0a0c3b/pages/teams/[teamId].vue?macro=true";
import { default as indexZSuwOixEvoMeta } from "/tmp/build_4d0a0c3b/pages/teams/index.vue?macro=true";
import { default as teamsixcwbx5eoVMeta } from "/tmp/build_4d0a0c3b/pages/teams.vue?macro=true";
import { default as indexoLwnqo9EFlMeta } from "/tmp/build_4d0a0c3b/pages/train/[id]/cancel/index.vue?macro=true";
import { default as indexCjko8ywez6Meta } from "/tmp/build_4d0a0c3b/pages/train/[id]/exchange/changeable-selection/index.vue?macro=true";
import { default as indexInj8hfba4FMeta } from "/tmp/build_4d0a0c3b/pages/train/[id]/exchange/section-selection/index.vue?macro=true";
import { default as indexJTjavWeqayMeta } from "/tmp/build_4d0a0c3b/pages/train/[id]/exchange/train-selection/index.vue?macro=true";
import { default as indexV3YkvJ4mt3Meta } from "/tmp/build_4d0a0c3b/pages/train/[id]/index.vue?macro=true";
import { default as driver_45licenseLN93mxLAPqMeta } from "/tmp/build_4d0a0c3b/pages/users/[id]/driver-license.vue?macro=true";
import { default as fidelityGL9JKwGQGTMeta } from "/tmp/build_4d0a0c3b/pages/users/[id]/fidelity.vue?macro=true";
import { default as indexRh5kv6AK8PMeta } from "/tmp/build_4d0a0c3b/pages/users/[id]/index.vue?macro=true";
import { default as passportIpSUfxU4vJMeta } from "/tmp/build_4d0a0c3b/pages/users/[id]/passport.vue?macro=true";
import { default as passwordVlXl3xANsrMeta } from "/tmp/build_4d0a0c3b/pages/users/[id]/password.vue?macro=true";
import { default as _91id_93Hcd0awbbAVMeta } from "/tmp/build_4d0a0c3b/pages/users/[id].vue?macro=true";
import { default as indexqFXNy5v2OZMeta } from "/tmp/build_4d0a0c3b/pages/users/index.vue?macro=true";
import { default as vouchersVgGYUC4Y07Meta } from "/tmp/build_4d0a0c3b/pages/vouchers.vue?macro=true";
import { default as component_45stubW5eK1quOorMeta } from "/tmp/build_4d0a0c3b/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubW5eK1quOor } from "/tmp/build_4d0a0c3b/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "administration",
    path: "/administration",
    meta: administrationepUkUEW8R7Meta || {},
    component: () => import("/tmp/build_4d0a0c3b/pages/administration.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-admins",
    path: "admins",
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/admins.vue").then(m => m.default || m)
  },
  {
    name: "administration-billing-id",
    path: "billing/:id()",
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/billing/[id].vue").then(m => m.default || m)
  },
  {
    name: "administration-billing",
    path: "billing",
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/billing/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-bookings",
    path: "bookings",
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/bookings.vue").then(m => m.default || m)
  },
  {
    name: "administration-ecostatistics",
    path: "ecostatistics",
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/ecostatistics.vue").then(m => m.default || m)
  },
  {
    name: "administration-exports",
    path: "exports",
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/exports.vue").then(m => m.default || m)
  },
  {
    name: "administration-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/fidelity.vue").then(m => m.default || m)
  },
  {
    name: "administration-invoices",
    path: "invoices",
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/invoices.vue").then(m => m.default || m)
  },
  {
    name: "administration-organization-policy",
    path: "organization/policy",
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/organization/policy.vue").then(m => m.default || m)
  },
  {
    name: "administration-payment-id",
    path: "payment/:id()",
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: "administration-payment",
    path: "payment",
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-statistics",
    path: "statistics",
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/statistics.vue").then(m => m.default || m)
  },
  {
    name: _91teamId_935GGWxqTHCkMeta?.name,
    path: "teams/:teamId()",
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: "administration-teams-teamId",
    path: "",
    meta: indexykRKgsuA0gMeta || {},
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/teams/[teamId]/invitation.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/teams/[teamId]/policy/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-policy-modify",
    path: "policy/modify",
    meta: modifyirhd2AkONGMeta || {},
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/teams/[teamId]/policy/modify.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/teams/[teamId]/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "administration-teams",
    path: "teams",
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-trip-labels",
    path: "trip-labels",
    component: () => import("/tmp/build_4d0a0c3b/pages/administration/trip-labels.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-swile",
    path: "/auth/swile",
    meta: swile9rnbLIS8FsMeta || {},
    component: () => import("/tmp/build_4d0a0c3b/pages/auth/swile.vue").then(m => m.default || m)
  },
  {
    name: "bookings",
    path: "/bookings",
    component: () => import("/tmp/build_4d0a0c3b/pages/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: "bookings-validator",
    path: "/bookings/validator",
    meta: indexaBDIwWUoyhMeta || {},
    component: () => import("/tmp/build_4d0a0c3b/pages/bookings/validator/index.vue").then(m => m.default || m)
  },
  {
    name: "bookings-validator-pending",
    path: "/bookings/validator/pending",
    meta: pendingGw50J9luymMeta || {},
    component: () => import("/tmp/build_4d0a0c3b/pages/bookings/validator/pending.vue").then(m => m.default || m)
  },
  {
    name: "car-id",
    path: "/car/:id()",
    component: () => import("/tmp/build_4d0a0c3b/pages/car/[id].vue").then(m => m.default || m)
  },
  {
    name: "flight-id-exchange",
    path: "/flight/:id()/exchange",
    component: () => import("/tmp/build_4d0a0c3b/pages/flight/[id]/exchange.vue").then(m => m.default || m)
  },
  {
    name: "flight-id-fares",
    path: "/flight/:id()/fares",
    component: () => import("/tmp/build_4d0a0c3b/pages/flight/[id]/fares.vue").then(m => m.default || m)
  },
  {
    name: "flight-id",
    path: "/flight/:id()",
    component: () => import("/tmp/build_4d0a0c3b/pages/flight/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgotLEiYIF6qLLMeta || {},
    component: () => import("/tmp/build_4d0a0c3b/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: "get-swile-app",
    path: "/get-swile-app",
    meta: get_45swile_45app9Aa9y8lSU1Meta || {},
    component: () => import("/tmp/build_4d0a0c3b/pages/get-swile-app.vue").then(m => m.default || m)
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/tmp/build_4d0a0c3b/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "hotel-id",
    path: "/hotel/:id()",
    component: () => import("/tmp/build_4d0a0c3b/pages/hotel/[id].vue").then(m => m.default || m)
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id()",
    component: () => import("/tmp/build_4d0a0c3b/pages/impersonate/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexsg4hSD0dGSMeta || {},
    component: () => import("/tmp/build_4d0a0c3b/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invitation-suffix",
    path: "/invitation/:suffix()",
    meta: _91suffix_93Nejxvtm7VUMeta || {},
    component: () => import("/tmp/build_4d0a0c3b/pages/invitation/[suffix].vue").then(m => m.default || m)
  },
  {
    name: "invite-token",
    path: "/invite/:token()",
    meta: _91token_93xyRM3dLtKxMeta || {},
    component: () => import("/tmp/build_4d0a0c3b/pages/invite/[token].vue").then(m => m.default || m)
  },
  {
    name: "journeys-id",
    path: "/journeys/:id()",
    component: () => import("/tmp/build_4d0a0c3b/pages/journeys/[id].vue").then(m => m.default || m)
  },
  {
    name: "journeys",
    path: "/journeys",
    component: () => import("/tmp/build_4d0a0c3b/pages/journeys/index.vue").then(m => m.default || m)
  },
  {
    name: "myteam",
    path: "/myteam",
    component: () => import("/tmp/build_4d0a0c3b/pages/myteam.vue").then(m => m.default || m),
    children: [
  {
    name: "myteam-members",
    path: "members",
    component: () => import("/tmp/build_4d0a0c3b/pages/myteam/members.vue").then(m => m.default || m)
  },
  {
    name: "myteam-policy",
    path: "policy",
    component: () => import("/tmp/build_4d0a0c3b/pages/myteam/policy.vue").then(m => m.default || m)
  },
  {
    name: "myteam-validators",
    path: "validators",
    component: () => import("/tmp/build_4d0a0c3b/pages/myteam/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "new-user",
    path: "/new-user",
    meta: new_45usercFVEWDuou1Meta || {},
    component: () => import("/tmp/build_4d0a0c3b/pages/new-user.vue").then(m => m.default || m)
  },
  {
    name: "password",
    path: "/password",
    meta: password0ZZZUpSwg0Meta || {},
    component: () => import("/tmp/build_4d0a0c3b/pages/password.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/tmp/build_4d0a0c3b/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: "search-car-search_id",
    path: "car/:search_id()",
    component: () => import("/tmp/build_4d0a0c3b/pages/search/car/[search_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "search-car",
    path: "car",
    component: () => import("/tmp/build_4d0a0c3b/pages/search/car/index.vue").then(m => m.default || m)
  },
  {
    name: "search-car-init-search_id",
    path: "car/init/:search_id()",
    component: () => import("/tmp/build_4d0a0c3b/pages/search/car/init/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-flight-search_id",
    path: "flight/:search_id()",
    component: () => import("/tmp/build_4d0a0c3b/pages/search/flight/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-flight",
    path: "flight",
    component: () => import("/tmp/build_4d0a0c3b/pages/search/flight/index.vue").then(m => m.default || m)
  },
  {
    name: "search-hotel-search_id",
    path: "hotel/:search_id()",
    component: () => import("/tmp/build_4d0a0c3b/pages/search/hotel/[search_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "search-hotel-search_id-rooms-hotelIds",
    path: "hotel/:search_id()/rooms/:hotelIds()",
    component: () => import("/tmp/build_4d0a0c3b/pages/search/hotel/[search_id]/rooms/[hotelIds].vue").then(m => m.default || m)
  },
  {
    name: "search-hotel",
    path: "hotel",
    component: () => import("/tmp/build_4d0a0c3b/pages/search/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: "search-train-search_id",
    path: "train/:search_id()",
    component: () => import("/tmp/build_4d0a0c3b/pages/search/train/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-train",
    path: "train",
    component: () => import("/tmp/build_4d0a0c3b/pages/search/train/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: teamsixcwbx5eoVMeta?.name,
    path: "/teams",
    meta: teamsixcwbx5eoVMeta || {},
    component: () => import("/tmp/build_4d0a0c3b/pages/teams.vue").then(m => m.default || m),
    children: [
  {
    name: _91teamId_938eF2oijT2EMeta?.name,
    path: ":teamId()",
    component: () => import("/tmp/build_4d0a0c3b/pages/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: "teams-teamId",
    path: "",
    component: () => import("/tmp/build_4d0a0c3b/pages/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_4d0a0c3b/pages/teams/[teamId]/invitation.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_4d0a0c3b/pages/teams/[teamId]/policy.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_4d0a0c3b/pages/teams/[teamId]/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams",
    path: "",
    component: () => import("/tmp/build_4d0a0c3b/pages/teams/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "train-id-cancel",
    path: "/train/:id()/cancel",
    component: () => import("/tmp/build_4d0a0c3b/pages/train/[id]/cancel/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-changeable-selection",
    path: "/train/:id()/exchange/changeable-selection",
    component: () => import("/tmp/build_4d0a0c3b/pages/train/[id]/exchange/changeable-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-section-selection",
    path: "/train/:id()/exchange/section-selection",
    component: () => import("/tmp/build_4d0a0c3b/pages/train/[id]/exchange/section-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-train-selection",
    path: "/train/:id()/exchange/train-selection",
    component: () => import("/tmp/build_4d0a0c3b/pages/train/[id]/exchange/train-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id",
    path: "/train/:id()",
    component: () => import("/tmp/build_4d0a0c3b/pages/train/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Hcd0awbbAVMeta?.name,
    path: "/users/:id()",
    component: () => import("/tmp/build_4d0a0c3b/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-driver-license",
    path: "driver-license",
    component: () => import("/tmp/build_4d0a0c3b/pages/users/[id]/driver-license.vue").then(m => m.default || m)
  },
  {
    name: "users-id-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_4d0a0c3b/pages/users/[id]/fidelity.vue").then(m => m.default || m)
  },
  {
    name: "users-id",
    path: "",
    component: () => import("/tmp/build_4d0a0c3b/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "users-id-passport",
    path: "passport",
    component: () => import("/tmp/build_4d0a0c3b/pages/users/[id]/passport.vue").then(m => m.default || m)
  },
  {
    name: "users-id-password",
    path: "password",
    component: () => import("/tmp/build_4d0a0c3b/pages/users/[id]/password.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/tmp/build_4d0a0c3b/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: "vouchers",
    path: "/vouchers",
    component: () => import("/tmp/build_4d0a0c3b/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "teams-vouchers",
    path: "/teams-vouchers",
    component: () => import("/tmp/build_4d0a0c3b/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: component_45stubW5eK1quOorMeta?.name,
    path: "/metrics",
    component: component_45stubW5eK1quOor
  },
  {
    name: component_45stubW5eK1quOorMeta?.name,
    path: "/en",
    component: component_45stubW5eK1quOor
  },
  {
    name: component_45stubW5eK1quOorMeta?.name,
    path: "/fr",
    component: component_45stubW5eK1quOor
  },
  {
    name: component_45stubW5eK1quOorMeta?.name,
    path: "/pt-BR",
    component: component_45stubW5eK1quOor
  },
  {
    name: component_45stubW5eK1quOorMeta?.name,
    path: "/en/:pathMatch(.*)",
    component: component_45stubW5eK1quOor
  },
  {
    name: component_45stubW5eK1quOorMeta?.name,
    path: "/fr/:pathMatch(.*)",
    component: component_45stubW5eK1quOor
  },
  {
    name: component_45stubW5eK1quOorMeta?.name,
    path: "/pt-BR/:pathMatch(.*)",
    component: component_45stubW5eK1quOor
  }
]